<template>
  <div v-editable="blok" class="schedule-demo">
    <div id="form-wrapper" class="schedule-demo__form">
      <FormErrorMessage v-if="isFailed" />
      <form class="demo-form" novalidate @submit.prevent="onSubmit">
        <div v-for="input in blok.inputs" :key="input._uid">
          <FormDynamicInput
            v-if="input.component === 'form-input'"
            v-model="formState[input.name]"
            :blok="input"
            :v="v$"
          />
          <component :is="input.component" v-else :blok="input" />
        </div>
        <div class="consents">
          <FormDynamicConsent
            v-for="consent in blok.consents"
            :key="consent._uid"
            v-model="formState[consent.name]"
            :blok="consent"
            :v="v$"
          />
        </div>
        <BaseButton
          class="button--submit-form"
          type="submit"
          data-testid="submit_button"
          :title="blok.button_text || $t('common.form.schedule_meeting')"
        >
          <template #text>
            <span>{{
              blok.button_text || $t('common.form.schedule_meeting')
            }}</span>
          </template>
        </BaseButton>
      </form>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { ValidationRuleWithParams } from '@vuelidate/core'
import { useVuelidate } from '@vuelidate/core'
import useSendData from '@/composables/useSendData'
import useValidation from '@/composables/useValidation'
import { getCookie } from '@/utils/helpers'

import useGtm from '@/composables/useGtm'
import type {
  FormInputStoryblok,
  SbFormGeneralDynamicStoryblok,
  FormConsentStoryblok,
} from '@/types'

interface Props {
  blok: SbFormGeneralDynamicStoryblok
}
interface ValidationRules {
  required: ValidationRuleWithParams
  email: ValidationRuleWithParams
  business: ValidationRuleWithParams
  phone: ValidationRuleWithParams
  maxLength: ValidationRuleWithParams
  minLength: ValidationRuleWithParams
  isNotPast: ValidationRuleWithParams
}
type Field = {
  name: string
  value: string
}
const props = defineProps<Props>()

const router = useRouter()

const emailFieldName = ref('')
const requiredConsent = reactive({
  name: '',
  text: '',
})

const getInitialValue = (type: string) => {
  if (type === 'Checkbox') {
    return false
  }
  if (type === 'CheckboxGroup') {
    return [] as string[]
  }
  return ''
}
const filteredInputs = computed(() =>
  props.blok.inputs?.filter(
    (input: FormInputStoryblok) => input.component === 'form-input',
  ),
)

const formInputs = filteredInputs.value?.reduce(
  (prevFields: FormInputStoryblok, inputField: FormInputStoryblok) => ({
    ...prevFields,
    [inputField.name]: getInitialValue(inputField.input_type),
  }),
  {} as FormInputStoryblok,
)

const formConsents = props.blok.consents?.reduce(
  (prevFields: FormConsentStoryblok, inputField: FormConsentStoryblok) => ({
    ...prevFields,
    [inputField.name]: false,
  }),
  {} as FormConsentStoryblok,
)

const formState = reactive({
  ...formInputs,
  ...formConsents,
})

const {
  isEmailBusiness,
  isEmailValid,
  isConsentAccepted,
  isValueSelected,
  isFieldRequired,
  isInvalidPhone,
  isPhoneTooLong,
  isPhoneTooShort,
  isCheckboxGroupRequired,
  isDateNotPast,
} = useValidation()

const getValidation = (field: FormInputStoryblok) => {
  const v = {} as ValidationRules
  if (field.required) {
    if (field.input_type === 'Select') {
      v.required = isValueSelected
    } else if (field.input_type === 'CheckboxGroup') {
      v.required = isCheckboxGroupRequired
    } else {
      v.required = isFieldRequired
    }
  }
  if (field.id.includes('email') || field.field_type === 'email') {
    v.email = isEmailValid
    v.business = isEmailBusiness
    emailFieldName.value = field.name
  }
  if (field.id.includes('phone')) {
    v.phone = isInvalidPhone
    v.maxLength = isPhoneTooLong
    v.minLength = isPhoneTooShort
  }
  if (field.id.includes('date') || field.field_type === 'date') {
    v.isNotPast = isDateNotPast
  }

  return v
}

const inputsValidation = filteredInputs.value?.reduce(
  (prevFields: FormInputStoryblok, inputField: FormInputStoryblok) => ({
    ...prevFields,
    [inputField.name]: getValidation(inputField),
  }),
  {} as FormInputStoryblok,
)

const consentsValidation = props.blok.consents?.reduce(
  (prevFields: FormConsentStoryblok, inputField: FormConsentStoryblok) => {
    if (inputField.required) {
      requiredConsent.name = inputField.name
      requiredConsent.text = inputField.text
      return {
        ...prevFields,
        [inputField.name]: {
          required: isConsentAccepted,
        },
      }
    }
    return {
      ...prevFields,
      [inputField.name]: {},
    }
  },
  {} as FormConsentStoryblok,
)

const rules = {
  ...inputsValidation,
  ...consentsValidation,
}
const v$ = useVuelidate(rules, formState)

const { scrollToError, isFailed, isLoading, sendFormData, sendGtmForm } =
  useSendData()
const route = useRoute()

const routeName = route.fullPath === '/' ? '/home' : route.fullPath

const {
  gtmSuccess,
  gtmError,
  gtmFirstTouch,
  gtmCompanyTypeData,
  gtmLeadProcessData,
  userId,
  companyId,
} = useGtm(formState, v$.value.$errors)

if (props.blok.gtm === 'Lead') {
  gtmFirstTouch('Lead')
} else {
  gtmFirstTouch()
}

const isFormValid = computed(() => {
  return !v$.value.$invalid
})
const thankYouRoute = computed(() => {
  if (
    !props.blok.custom_thank_you ||
    props.blok.custom_thank_you.cached_url === ''
  ) {
    return '/thank-you'
  }

  return props.blok.custom_thank_you.cached_url
})
const { cachedUrl } = useStoryblokLink({ cached_url: thankYouRoute.value })

const genericFormUrl = (id = '0d7d8895-b4d6-4fd8-9e23-7ae77f2592de') => {
  return `https://api.hsforms.com/submissions/v3/integration/submit/5731513/${id}`
}

const config = useRuntimeConfig()

const onSubmit = async () => {
  v$.value.$touch()
  const data = {
    submittedAt: Date.now(),
    fields: [
      ...(filteredInputs.value?.map((input: FormInputStoryblok) => {
        if (input.input_type === 'CheckboxGroup') {
          return {
            name: `${input.id_prefix || ''}${input.id}`,
            value: formState[input.name].join(', '),
          }
        }
        return {
          name: `${input.id_prefix || ''}${input.id}`,
          value: formState[input.name],
        }
      }) as Field[]),
      {
        name: 'user_id__infermedica_',
        value: userId(formState[emailFieldName.value]),
      },
      {
        name: 'company_id__infermedica_',
        value: companyId(formState[emailFieldName.value]),
      },
      {
        name: 'user_s_origin',
        value: router.options.history.state.back || '',
      },
    ],

    context: {
      hutk: getCookie('hubspotutk'),
      pageUri: window.location.href,
      pageName: routeName,
    },
    legalConsentOptions: {
      consent: {
        consentToProcess: formState[requiredConsent.name],
        text: requiredConsent.text,

        communications: props.blok.consents?.map(
          (consent: FormConsentStoryblok) => {
            return {
              value: formState[consent.name],
              subscriptionTypeId: parseInt(consent.consent_id),
              text: consent.text,
            }
          },
        ),
      },
    },
  }

  if (isFormValid.value) {
    if (props.blok.gtm === 'Lead') {
      gtmSuccess({
        eventName: 'Lead',
        consent: formState.consentToSendNewsletter,
      })
    } else {
      gtmSuccess({
        consent: formState.consentToProcess,
      })
    }

    await sendFormData(genericFormUrl(props.blok.form_id), data, {
      displayChiliPiper: false,
      thankYouRoute: cachedUrl.value,
    })
    await sendGtmForm(
      config.public.analytics.leadProcessUrl,
      gtmCompanyTypeData,
    )
    await sendGtmForm(
      config.public.analytics.leadProcessUrl,
      gtmLeadProcessData,
    )
    isLoading.value = false

    return
  }
  await nextTick()
  if (props.blok.gtm === 'Lead') {
    gtmError('Lead')
  } else {
    gtmError()
  }
  scrollToError()
}
</script>

<style lang="scss" scoped>
.schedule-demo {
  text-align: left;
}

.consents {
  margin: 40px 0;

  > p > a {
    display: inline-block;
  }

  &__withdrawal {
    margin-top: 40px;
  }
}

.button--submit-form {
  display: block !important;
  margin: 0 auto;
}
</style>
